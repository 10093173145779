export default {
  // 试用中心-首页模块
  '1-1-1': 'free-trial-home.js',
  // 试用中心-公用埋点模块
  '1-1-2': 'free-trial-common.js',
  // 试用中心-个人中心页模块
  '1-2-1': 'index.js',
  // 试用中心-提交报告页模块
  '1-3-1': 'index.js',
  // 试用中心-报告详情页模块
  '1-4-1': 'index.js',
  // 列表页-模块
  '1-5-1': 'index.js',
  // 列表页-模块
  '1-5-2': 'shop-look.js',
  // 列表页-搜索框
  '1-5-3': 'search.js',
  // 列表页一口价列表
  '1-5-4': 'under-prices.js',
  // 详情页-模块
  '1-6-1': 'index.js',
  // 详情页评论弹窗模块
  '1-6-2': 'reviews.js',
  // 详情页style gallery模块
  '1-6-3': 'stylegallery.js',
  // 详情页middle模块
  '1-6-4': 'middle.js',
  // 详情页bottom模块
  '1-6-5': 'bottom.js',
  // 主图相关埋点
  '1-6-6': 'gallery.js',
  // 详情页 评论模块
  '1-6-7': 'good-reviews',
  // 相似商品结果页-模块
  '1-7-1': 'index.js',
  // 购物车页面
  '1-8-1': 'index.js',
  // 购物车checkout模块
  '1-8-2': 'checkout.js',
  // 购物车列表
  '1-8-3': 'list.js',
  // 购物车凑单
  '1-8-4': 'add-item.js',
  // 购物车促销
  '1-8-5': 'promotion.js',
  // 购物车无货弹窗
  '1-8-6': 'sold-out-box.js',
  // 购物车性能优化埋点抽离
  '1-8-7': 'cart.js',
  // 购物车分享落地页
  '1-8-8': 'share.js',
  // 购物车附属品选择弹窗
  '1-8-9': 'cart-appendage.js',
  // 购物车楼层
  '1-8-11': 'cart-floor.js',
  // 钩子商品落地页
  '1-9-3': 'flash-sale-hook-product.js',
  // 专区闪购落地页
  '1-9-2': 'specials-zone.js',
  // 闪购融合版本
  '1-9-4': 'flash-sale-bff.js',
  // 收藏夹
  '1-10-1': 'index.js',
  // 收藏夹 - 多页面共有埋点
  '1-10-2': 'common.js',
  // 收藏夹 - items & boards（overview.js）
  '1-10-3': 'overview.js',
  // 收藏夹 - 商品列表（products.js）
  '1-10-4': 'products.js',
  // 收藏夹 - 分组详情（group.js）
  '1-10-5': 'group.js',
  // 下单页
  '1-11-1': 'index.js',
  '1-11-2': 'sold_out.js',
  '1-11-3': 'shopping_bag.js',
  // 支付成功页
  '1-12-1': 'index.js',
  // category / sidecat
  '1-14-1': 'index.js',
  // 首页
  '1-15-1': 'category-recommend.js',
  // 个人中心
  '1-16-1': 'index.js',
  // 物流轨迹(新)
  '1-17-2': 'new-track.js',
  // Me页面地址
  '1-18-1': 'index.js',
  // 订单详情
  '1-19-1': 'index.js',
  // 整单退页面
  '1-19-2': 'refund-order.js',
  // 订单价保落地页
  '1-19-3': 'price-guarantee.js',
  // 订单列表
  '1-20-1': 'index.js',
  // 订单-结果页（弹窗）
  '1-20-2': 'success-panel.js',
  // checkout首单地址
  '1-23-1': 'index.js',
  // 个人中心 优惠券
  '1-25-1': 'coupon.js',
  // 个人中心
  '1-25-2': 'index.js',
  // 个人中心 积分页
  '1-25-3': 'points.js',
  // 个人中心 站內信
  '1-25-4': 'message.js',
  // 个人中心 钱包
  '1-25-5': 'wallet.js',
  // 个人中心 支付选项
  '1-25-6': 'payments.js',
  // 个人中心 付费会员
  '1-25-7': 'prime.js',
  // 个人中心 付费会员记录页
  '1-25-8': 'prime-record.js',
  // 个人中心 试用会员页
  '1-25-9': 'prime-trial.js',
  // 个人中心 问卷列表
  '1-25-10': 'survey.js',
  // 个人中心 付费会员credit记录页
  '1-25-13': 'prime-credit-record.js',
  // 个人中心 免费礼物弹窗
  '1-25-15': 'free-gift.js',
  // 新个人中心页面埋点
  '1-25-11': 'user-index.js',
  // 新个人中心页面埋点
  '1-25-12': 'user-index-entry.js',
  // 首页 - 顶部导航
  '1-15-2': 'homepage-toptab.js',
  // 礼盒雨
  '1-21-1': 'gift-box.js',
  // 堆箱子
  '1-21-2': 'pile-up-box.js',
  // 打地鼠
  '1-21-3': 'hit-the-gopher.js',
  // 集卡升级
  '1-21-5': 'collection.js',
  // shein shop
  '1-21-6': 'shein-shop.js',
  // 合成花束
  '1-21-7': 'csr-flowers',
  // 动物
  '1-21-8': 'csr-animal',
  '1-21-9': 'designer-detail',
  // 许愿
  '1-21-13': 'wish',
  // 接水果
  '1-21-10': 'pick-fruit',
  // 萌宠
  '1-21-11': 'config-pet',
  '1-21-15': 'game-common',
  // 机器人
  '1-24-1': 'index.js',
  '1-26-1': 'index.js',
  // 首页 - 悬浮icon
  '1-15-3': 'suspension_icon.js',
  // 首页 - 底部商品曝光
  '1-15-4': 'vertical-list.js',
  // 直播页
  '1-27-1': 'index.js',
  // 礼品卡订单详情
  '1-28-1': 'index.js',
  // 礼品卡订单列表
  '1-29-1': 'index.js',
  // 退货页面
  '1-30-1': 'index.js',
  // 退货退款记录页
  '1-30-2': 'refund-return-record.js',
  // 欧洲市场新增撤销权功能
  '1-30-3': 'order-revoke-power.js',
  // 订单评论页面
  '1-31-1': 'index.js',
  // faq
  '1-32-1': 'index.js',
  //订单回收站
  '1-33-1': 'index.js',
  // 数据主体权利中心
  '1-35-1': 'index.js',
  // dsr数据删除页
  '1-35-2': 'del.js',
  //卡支付页面
  '1-34-1': 'index.js',
  // 买家秀
  '1-36-1': 'index.js',
  // notification
  '1-37-1': 'index.js',
  // inner remind
  '1-38-1': 'index.js',
  // payCod
  '1-39-1': 'index.js',
  // article
  '1-40-1': 'index.js',
  // 海关拦截包裹清单页
  '1-41-1': 'index.js',
  // 巴西清关页
  '1-41-2': 'clearance.js',
  // 发票中心列表
  '1-42-1': 'index.js',
  // 合单承接页
  '1-43-1': 'index.js',
  // 订单拒收
  '1-46-1': 'index.js',
  // 帐号删除页面
  '1-47-1': 'index.js',
  // 评论中心
  '1-48-1': 'index.js',
  // sheinx设计师列表
  '1-49-1': 'index.js',
  // romwevip
  '1-50-1': 'index.js',
  // 风控审单上传页
  '1-51-1': 'index.js',
  // 分享赚活动领券页
  '1-52-1': 'index.js',
  // blik
  '1-53-1': 'index.js',
  // 店铺评论页
  '1-54-1': 'index.js',
  // 礼品卡下单页
  '1-55-1': 'index.js',
  // token卡绑定
  '1-56-1': 'index.js',
  // 短信退订页
  '1-57-1': 'index.js',
  // 短信退订 结果页
  '1-57-2': 'result.js',
  // 邮箱解绑
  '1-59-1': 'index.js',
  // 账号安全页
  '1-58-1': 'security.js',
  // 账号安全页
  '1-58-2': 'personal.js',
  // 手机号换绑
  '1-60-1': 'index.js',
  // 支付结果未知状态页
  '1-61-1': 'index.js',
  // 回归用户券包弹窗
  '1-62-1': 'index.js',
  // 店铺通用埋点
  '1-63-0': 'store-common.js',
  // 店铺品类页面
  '1-63-1': 'store-category.js',
  // 店铺品牌页面
  '1-63-2': 'store-brands.js',
  // 店铺推荐组件
  '1-63-3': 'store-recommend.js',
  // 店铺品牌页面
  '1-64-1': 'store-following.js',
  // 营销落地页
  '1-65-1': 'index.js',
  // 评论详情页
  '1-66-1': 'index.js',
  // 热销榜&折扣榜
  '1-67-1': 'rank-products.js',
  // 礼品卡详情页
  '1-68-1': 'index.js',
  // 不贵频道
  '1-69-1': 'nav-bar.js',
  '1-69-2': 'flash-sale.js',
  '1-69-3': 'tab-category.js',
  '1-69-4': 'product-list.js',
  '1-69-5': 'leave-dialog.js',
  // 订单换货列表(新)
  '1-70-1': 'index.js',
  // 品牌馆（品牌频道）
  '1-71-1': 'index.js',
  // 折扣落地页
  '1-72-1': 'index.js',
  // 个人中心-news
  '1-73-1': 'index.js',
  // 趋势频道
  '1-74-1': 'index.js',
  // 趋势落地页
  '1-75-1': 'index.js',
  // 趋势搭配页
  '1-76-1': 'index.js',
  // 趋势词墙落地页
  '1-80-1': 'index.js',
  // 信息流： 用户行为落地页
  '1-77-1': 'index.js',
  // 支付： 联名卡落地页
  '1-78-1': 'index.js',
  // 试用中心——融合版本
  '1-79-1': 'index.js',
  // 品质卖点商品落地页
  '1-81-1': 'index.js',
}
